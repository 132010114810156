import { useEffect, useState } from 'react'

import { AccountTypesEnum, SetSettingsGaDebitCardProps } from 'services/ga-debit-card/types'
import {
  useGetSettingsGaDebitCard,
  useSetSettingsGaDebitCard,
} from 'services/ga-debit-card/useGaDebitCard'
import { useSearchParams } from 'react-router-dom'

import { interWbNavigate } from '@interco/inter-webview-bridge'
import { useQueryClient } from '@tanstack/react-query'

export const useGaDebitCard = () => {
  const queryClient = useQueryClient()
  const [searchParams, setSearchParams] = useSearchParams()
  const cardId = String(searchParams.get('cardId') || searchParams.get('cardid')).replaceAll(
    ' ',
    '+',
  )

  const security = String(searchParams.get('security')).replaceAll(' ', '+')

  const [accountSelected, setAccountSelected] = useState<AccountTypesEnum>(
    AccountTypesEnum.ACCOUNT_CAYMAN,
  )

  const handleBackButton = () => interWbNavigate.requestGoBack()

  const getSettingsProps = {
    'x-inter-card-id': cardId ?? '',
    'x-inter-ia-security': security ?? '',
  }

  const { data, isLoading: isGettingSettings } = useGetSettingsGaDebitCard(getSettingsProps, {
    enabled: !!cardId && !!security,
  })

  const {
    mutateAsync: setCardSettings,
    isLoading: isCreatingConfig,
    isError,
    reset,
  } = useSetSettingsGaDebitCard({
    onSuccess: () => {
      queryClient.invalidateQueries(['get-settings-ga-debit-card', getSettingsProps])
    },
  })

  const isInAnalysis = data && data.find((item) => item.value === AccountTypesEnum.ACCOUNT_BRAZIL)

  function onSubmit() {
    if (!cardId || !security) return

    const props: SetSettingsGaDebitCardProps = {
      operation: 'DEBIT_CARD_ACCOUNT',
      value: accountSelected,
      'x-inter-ia-security': security,
      'x-inter-card-id': cardId,
    }

    setCardSettings(props)
  }

  useEffect(() => {
    if (isError) {
      setSearchParams(`${searchParams.toString()}&isError=true`)
      reset()
    }
  }, [isError, reset, searchParams, setSearchParams])

  return {
    accountSelected,
    setAccountSelected,
    handleBackButton,
    onSubmit,
    isInAnalysis,
    isGettingSettings,
    isCreatingConfig,
    searchParams,
  }
}
