import { useNavigate } from 'react-router-dom'
import { useAppInfo } from 'hooks/useAppInfo'
import { IconTheme } from 'components/IconTheme'
import { Term } from 'pages/TermsAndConditions/types'
import data from 'pages/TermsAndConditions/data'

import { AppBar } from '@interco/inter-ui/components/AppBar'
import { Grid } from '@interco/inter-ui/components/Grid'
import { FlexColumn, FlexRow } from '@interco/inter-ui/components/Flex'
import { interWbNavigate } from '@interco/inter-webview-bridge'
import { Card } from '@interco/inter-ui/components/Card'
import { Text } from '@interco/inter-ui/components/Text'

export function TermsAndConditions() {
  const navigate = useNavigate()
  const appInfo = useAppInfo()
  const selectedLanguage = appInfo?.language ?? 'pt-BR'

  const handleBackButton = () => interWbNavigate.requestGoBack()

  const handleSelectTerm = (term: Term) =>
    navigate(`/terms-and-conditions-view-document/${term.id}/is-webview`)

  const title: Record<string, string> = {
    'pt-BR': 'Termos e condições',
    'en-US': 'Terms and conditions',
    'es-ES': 'Términos y condiciones',
  }

  return (
    <>
      <AppBar
        leftIcon={
          <IconTheme
            icon="orangeds/MD/arrow-left"
            width={24}
            height={24}
            onClick={handleBackButton}
          />
        }
      >
        {title[selectedLanguage]}
      </AppBar>
      <Grid className="p-4 gap-y-4">
        {data[selectedLanguage].map((item) => (
          <Card key={item.title} onClick={() => handleSelectTerm(item)}>
            <FlexRow alignItems="center" gap="16px">
              <IconTheme
                icon="orangeds/MD/pdf-file"
                className="shrink-0"
                width={24}
                height={24}
                color="var(--gray500)"
              />
              <FlexColumn gap="4px">
                <Text variant="body-3" as="p" bold colorWeight={500}>
                  {item.title}
                </Text>
                <Text variant="caption-1" as="p">
                  {item.description}
                </Text>
              </FlexColumn>
              <IconTheme
                icon="orangeds/MD/chevron-right"
                className="shrink-0"
                width={24}
                height={24}
              />
            </FlexRow>
          </Card>
        ))}
      </Grid>
    </>
  )
}
