import { createBrowserRouter } from 'react-router-dom'
import { TermsAndConditions } from 'pages/TermsAndConditions'
import { AccountInfo } from 'pages/AccountInfo'
import { TermsAndConditionsViewer } from 'pages/TermsAndConditions/term-and-conditions-viewer'
import { InstantPayments } from 'pages/InstantPayments'
import { GaDebitCard } from 'pages/GaDebitCard'
import { TermsGlobalAccount } from 'pages/TermsGlobalAccount'
import { TermsGlobalAccountViewer } from 'pages/TermsGlobalAccount/terms-global-account-viewer'

export const router = createBrowserRouter([
  {
    path: '',
  },
  {
    path: 'account-info',
    element: <AccountInfo />,
  },
  {
    path: 'terms-and-conditions',
    element: <TermsAndConditions />,
  },
  {
    path: 'terms-and-conditions-view-document/:id/:isWebview?',
    element: <TermsAndConditionsViewer />,
  },
  {
    path: 'instant-payments',
    element: <InstantPayments />,
  },
  {
    path: 'ga-debit-card',
    element: <GaDebitCard />,
  },
  {
    path: 'terms-global-account',
    element: <TermsGlobalAccount />,
  },
  {
    path: 'terms-global-account-view-document/:id/:isWebview',
    element: <TermsGlobalAccountViewer />,
  },
])
