import { IconTheme } from 'components/IconTheme'
import { useSearchParams } from 'react-router-dom'

import { AppBar } from '@interco/inter-ui/components/AppBar'
import { FlexColumn } from '@interco/inter-ui/components/Flex'
import { Text } from '@interco/inter-ui/components/Text'
import { Button } from '@interco/inter-ui/components/Button'

type GaDebitCardErrorProps = {
  handleBackButton: () => void
}

export const GaDebitCardError = ({ handleBackButton }: GaDebitCardErrorProps) => {
  const [searchParams, setSearchParams] = useSearchParams()

  return (
    <FlexColumn height="100vh" justifyContent="flex-start">
      <AppBar
        leftIcon={
          <IconTheme
            icon="orangeds/MD/arrow-left"
            width={24}
            height={24}
            onClick={handleBackButton}
          />
        }
      />
      <FlexColumn
        className="p-6"
        height="100%"
        alignItems="center"
        justifyContent="center"
        gap="0.5rem"
      >
        <div className="flex items-center justify-center bg-[var(--error500)] rounded-full size-16 mb-4 mt-auto">
          <IconTheme icon="bidis/layout/close" width={32} height={32} color="white" />
        </div>
        <Text variant="headline-h1" colorWeight={500} semiBold className="text-center">
          Houve um erro por aqui
        </Text>
        <Text variant="body-3" className="text-center">
          Você pode tentar novamente mais tarde.
        </Text>
        <FlexColumn className="mt-auto" gap="1rem">
          <Button
            fullWidth
            onClick={() => {
              searchParams.delete('isError')
              setSearchParams(searchParams.toString())
            }}
          >
            Tentar novamente
          </Button>
        </FlexColumn>
      </FlexColumn>
    </FlexColumn>
  )
}
